import { cn } from "@nextui-org/react";
import { useSearch } from "~/stores/search";
import Search from "./search";

export default function Followup() {
  const { loading, results } = useSearch();

  const isLoading = loading && results.length === 0;

  return (
    <div className="sticky bottom-4 md:bottom-8 z-10 flex justify-center mx-2 sm:mx-6">
      <div
        className={cn(
          "max-w-xl bg-background bg-opacity-40 p-2 rounded-full backdrop-blur-md transition-all w-16 h-16 flex justify-center items-center",
          !isLoading && "w-full"
        )}
      >
        {isLoading ? (
          <></>
        ) : (
          <div className="bg-background rounded-full w-full">
            <Search placeholder="Ask AI something else..." />
          </div>
        )}
      </div>
    </div>
  );
}
