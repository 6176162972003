import { useSearch } from "~/stores/search";
import Clusters from "./clusters";
import Provider from "./provider";
import LoadMore from "./load-more";
import Logo from "./logo";

export default function SearchResults() {
  const {
    loading,
    clusters,
    results,
    loadMore,
    message,
    finished,

    notFoundMessage,
    query,
  } = useSearch();

  return (
    <>
      {loading &&
        clusters.length === 0 &&
        results.length === 0 &&
        message.length === 0 &&
        notFoundMessage === undefined && (
          <div className="-mx-4 -sm:mx-6 mb-8 px-4 max-w-screen overflow-hidden flex-1 flex flex-col justify-center items-center relative">
            <Logo className="h-40 w-40 md:h-64 md:w-64" animating={true} />
          </div>
        )}

      {notFoundMessage && (
        <div className="flex-1 flex flex-col justify-center items-center py-10 gap-8">
          <p className="text-4xl font-bold text-default-500 text-center">
            Oops!
          </p>
          <p className="text-center mt-2">{notFoundMessage}</p>
        </div>
      )}

      <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {results.map((provider, idx) => {
          return (
            <>
              {idx === 12 && (
                <div className="col-span-1 sm:col-span-2 lg:col-span-3 xl:col-span-4">
                  <Clusters clusters={clusters} query={query} />
                </div>
              )}
              <div key={provider.slug}>
                <Provider key={provider.slug} {...provider} q={query} />
              </div>
            </>
          );
        })}
      </div>

      {results.length > 0 && loading && (
        <div className="py-4 flex flex-col gap-2 items-center">
          <Logo className="h-40 w-40 md:h-64 md:w-64" animating={true} />
          <p className="text-sm text-center text-default-500">
            Loading more results
          </p>
        </div>
      )}

      {results.length > 0 && finished && !loading && (
        <div className="py-4 flex flex-col gap-2 items-center">
          <p className="text-sm text-center text-default-500">
            No more results.
          </p>
        </div>
      )}

      {results.length > 0 && !finished && !loading && (
        <LoadMore load={loadMore} />
      )}
    </>
  );
}
