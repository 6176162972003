"use client";

import { Card, CardBody } from "@nextui-org/card";
import { HiSparkles } from "react-icons/hi2";
import { Cluster } from "~/stores/search";
import posthog from "posthog-js";
import { getPublicAssetURL } from "~/utils/assets";
import { Link } from "@remix-run/react";
import { track } from "~/utils/track";

type ClustersProps = {
  query: string | null;
  clusters: Cluster[];
};

export default function Clusters({ clusters, query }: ClustersProps) {
  return (
    <div className="flex flex-col -ml-4 sm:ml-0 sm:rounded-2xl w-screen sm:w-auto bg-primary-500 bg-gradient-to-br from-primary-500 to-pink-600">
      <div className="p-4 flex gap-2 pt-8">
        <div className="bg-background rounded-full w-8 h-8 aspect-square flex items-center justify-center">
          <HiSparkles className="w-5 h-5 text-primary" />
        </div>
        <div className="bg-background p-3 rounded-b-xl rounded-tr-2xl shadow-2xl flex-1">
          <h3 className="font-bold">Not sure what you are looking for?</h3>
          <p className="">Here are some AI generated ideas to help you</p>
        </div>
      </div>

      <div className="flex gap-4 py-8 overflow-x-auto px-4">
        {clusters.map((c) => (
          <Link
            key={c.name}
            to={`/search?q=${encodeURIComponent(c.query)}`}
            onClick={() => {
              posthog.capture("cluster_opened", {
                query,
                name: c.name,
                merged_query: c.query,
              });
              track("cluster_opened", {
                query,
                name: c.name,
                merged_query: c.query,
              });
            }}
          >
            <Card className="flex-shrink-0 w-48 h-full">
              <img
                loading="lazy"
                className="object-cover h-48 w-48 aspect-square"
                alt={c.name}
                src={c.image ? getPublicAssetURL(c.image) : "/assets/blur.webp"}
              />
              <CardBody>
                <p>{c.name}</p>
              </CardBody>
            </Card>
          </Link>
        ))}
      </div>
    </div>
  );
}
