import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

export default function LoadMore({ load }: { load: () => void }) {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      load();
    }
  }, [inView, load]);

  return <div className="w-4 h-4" ref={ref}></div>;
}
