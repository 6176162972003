import { MetaFunction } from "@remix-run/cloudflare";
import { useSearchParams } from "@remix-run/react";
import { useEffect } from "react";
import { HiSparkles } from "react-icons/hi2";

import Followup from "~/components/followup";

import Navbar from "~/components/navbar";

import SearchResults from "~/components/search-results";
import { useSearch } from "~/stores/search";

import { useUser } from "~/stores/user";

export const meta: MetaFunction = ({ location }) => {
  const q = new URLSearchParams(location.search).get("q");

  return [
    { title: `${q} - Search` },
    {
      name: "robots",
      content: "noindex, follow",
    },
    {
      name: "googlebot",
      content: "noindex, follow",
    },
    {
      name: "description",
      content:
        "Discover India's top service providers on Tribe! From photographers to interior designers and fitness trainers, find the perfect match for your needs today.",
    },
  ];
};

export default function SearchPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { location, setLocation, locationLoading } = useUser();
  const { query, setQuery, lat, setLat, lng, setLng, label, setLabel } =
    useSearch();

  const qParam = searchParams.get("q");
  const latParam = searchParams.get("lat");
  const lngParam = searchParams.get("lng");
  const labelParam = searchParams.get("label");

  useEffect(() => {
    if (qParam) {
      setQuery(qParam);
    }
  }, [qParam, setQuery]);

  useEffect(() => {
    if (latParam) {
      setLat(latParam);
    }
  }, [latParam, setLat]);

  useEffect(() => {
    if (lngParam) {
      setLng(lngParam);
    }
  }, [lngParam, setLng]);

  useEffect(() => {
    if (labelParam) {
      setLabel(labelParam);
    }
  }, [labelParam, setLabel]);

  useEffect(() => {
    if (locationLoading) return;

    if (latParam && lngParam && labelParam) {
      setLocation({
        lat: Number(latParam),
        lng: Number(lngParam),
        label: decodeURIComponent(labelParam),
      });
    }
  }, [labelParam, latParam, lngParam, locationLoading, setLocation]);

  useEffect(() => {
    if (locationLoading) return;

    if (
      location &&
      location.lat !== Number(lat) &&
      location.lng !== Number(lng) &&
      location.label !== label
    ) {
      setSearchParams((prev) => {
        prev.set("lat", location.lat.toString());
        prev.set("lng", location.lng.toString());
        prev.set("label", encodeURIComponent(location.label));
        return prev;
      });
    }
  }, [location, setSearchParams, lat, lng, label, locationLoading]);

  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center">
        <div className="w-full max-w-7xl">
          <div className="min-h-[calc(100dvh-64px)] py-6 flex flex-col px-4 sm:px-6 gap-8">
            <div className="flex space-x-2 items-center">
              <HiSparkles className="w-6 h-6 text-primary" />
              <h2 className="text-2xl font-medium">{query}</h2>
            </div>
            <SearchResults />
          </div>
          <Followup />
        </div>
      </div>
    </>
  );
}
